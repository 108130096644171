import React, { Component } from 'react';
import styled from 'styled-components';

export default class Footer extends Component {
  render() {
    return (
      <FootsyWrapper>
        <Footsy>
          <ConstantContact>
            <Footy>
              <p className="company">LETS CONNECT</p>
            </Footy>
            <Footy>
              <p style={{ color: '#808080' }} className="capital">
                I'm always interested in discussing design and web development. <br />
                ...or golf 😃
                <br />
              </p>
            </Footy>
            <Footy>
              <a className="message" href="mailto:amutaa@gmail.com">
                <i class="far fa-envelope fa-2x"></i>
                <p className="messageMe">MESSAGE ME</p>
              </a>
            </Footy>
          </ConstantContact>
          <ConstantContact>
            <Footy />
            <Footy>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="links"
                href="https://www.linkedin.com/in/adam-mutaawe-a55194172/"
              >
                <i className="linkedin" class="fab fa-linkedin fa-3x linkedin" />
              </a>
              <a target="_blank" rel="noopener noreferrer" className="links" href="https://github.com/AMutaa">
                <i className="github" class="fab fa-github fa-3x github" />
              </a>
            </Footy>
            <Footy />
          </ConstantContact>
        </Footsy>
      </FootsyWrapper>
    );
  }
}
const FootsyWrapper = styled.div`
  @media (min-device-width: 320px) and (max-device-width: 480px) {
    max-width: 100vw;
    max-height: 100vh;
    min-height: 30vh;
  }
`;

const Footsy = styled.div`
  min-height: 40vh;
  max-width: 70vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (min-device-width: 320px) and (max-device-width: 480px) {
    flex-direction: column;
    max-width: 100%;
    min-height: 70vh;
    max-height: 30vh;
  }
`;

const ConstantContact = styled.div`
  flex: 1 1 50%;
  height: 20vh;
  display: grid;
  grid-template-rows: 1fr 2fr 1fr;
  grid-gap: 10px;
  color: white;
  margin: 0 4em;
  padding: 2em;
  text-align: center;
  a {
    color: #808080;
  }
  @media (min-device-width: 320px) and (max-device-width: 480px) {
    height: 10vh;
  }
`;

const Footy = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;
