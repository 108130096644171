import React, { Component } from 'react';
import styled from 'styled-components';
import Nav from './Navbar';

export default class Intro extends Component {
  render() {
    return (
      <IntroWrap>
        <Nav />
        <Introduc>
          <p className="hello">Hey. I'm Adam Mutaawe.</p>
          <p className="intro">
            Software engineer with a main focus on front-end development. I enjoy building functional, efficient,
            elegant and thoughtfully designed User Interfaces. I also enjoy running, listening to audio books and
            travelling. This is my personal website, it is always evolving and you're always welcome here.
          </p>
        </Introduc>
      </IntroWrap>
    );
  }
}

const IntroWrap = styled.div`
  background: #292d39;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  min-height: 100vh;
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  }
`;

const Introduc = styled.div`
  color: #ffffff;
  width: 45vw;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    width: 80%;
    max-height: 100vh;
  }
`;
