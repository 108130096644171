import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default class Navbar extends Component {
  render() {
    return (
      <Nav>
        <Link to="/" style={{ textDecoration: "none" }}>
          <Logo>
            <h2 className="am">AM</h2>
          </Logo>
        </Link>
      </Nav>
    );
  }
}

const Nav = styled.div`
  display: flex;
  position: fixed;
  z-index: 1100;
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    justify-content: center;
    position: static;
  }
`;
const Logo = styled.div`
  font-size: 3em;
  color: white;
  background: #292d39;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    position: static;
  }
`;
