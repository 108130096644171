import React, { Component } from 'react';
import styled from 'styled-components';
import pencil from '../assets/pencil.png';

export default class Engineer extends Component {
  render() {
    return (
      <Eng className="work">
        <Engine>
          <Content>
            <Pencil className="pencil">
              <img
                src={pencil}
                style={{
                  width: '100px',
                  borderRadius: '100px',
                  margin: '0 auto'
                }}
                alt=""
              ></img>
              <Dots
                style={{
                  margin: '5px auto',
                  transform: 'rotateX(120deg)',
                  paddingLeft: '3px',
                  cursor: 'pointer'
                }}
              />
            </Pencil>
            <p className="company">PUBLICIS SAPIENT</p>
            <p className="engineer">front end engineer</p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '30px 0',
                cursor: 'pointer'
              }}
            >
              <Dots />
              <Dots />
              <Dots />
              <Dots />
              <Dots />
            </div>
            <p className="capital">
              I’m currently leveraging Agile practices to aid WAWA, a quick service restaurant, convenience store and
              fuel provider on the east coast, through their Digital Transformation.
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '10px 0',
                  cursor: 'pointer'
                }}
              >
                <Dots />
              </div>
              I am building 💻 a front end with clean UI and great UX for <br /> WAWA Catering – an e-commerce platform.
              <br /> I work with React and Redux on a day-to-day and spend the rest of my time working to advance our
              Front-end development practice.
            </p>
          </Content>
        </Engine>
      </Eng>
    );
  }
}

const Eng = styled.div`
  min-height: 100vh;
  max-height: 100vh;
`;
const Engine = styled.div`
  min-height: 100vh;
  background-image: radial-gradient(#292d39, #15171e);
  opacity: 0.94;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
`;

const Content = styled.div`
  width: 27vw;
  text-align: center;
  z-index: 100000;
  padding-bottom: 100px;
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    width: 80vw;
  }
`;

const Dots = styled.div`
  width: 7px;
  height: 7px;
  margin: 2px 3px;
  background: white;
  border-radius: 10px;

  &: hover {
    background: black;
    opacity: 0.6;
    transition: 0.5s;
  }
`;
const Pencil = styled.div`
  height: 150px;
  width: 150px;
  margin: 50px auto;
  border-radius: 100px;
  opacity: 0.8;
  background: black;
  z-index: 100;
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  }
`;
