import React, { Component } from 'react';
import styled from 'styled-components';

export default class Copyright extends Component {
  render() {
    return (
      <CopyrightFootsy>
        <p style={{ fontSize: '0.8em', letterSpacing: '0.02em' }}>
          <i class="far fa-copyright fa-spin"></i> 2022 - Adam Mutaawe. All Rights Reserved.
        </p>
      </CopyrightFootsy>
    );
  }
}
const CopyrightFootsy = styled.div`
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #808080;
`;
