import React, { Component } from "react";
import styled from "styled-components";
import Intro from "./Intro";
import Eng from "./Engineer";
import Footer from "./Footer";
import Copyright from "./Copyright";

export default class MainView extends Component {
  render() {
    return (
      <MainWrapper>
        <section>
          <Intro />
        </section>
        <section>
          <Eng />
        </section>
        <section style={{ background: "linear-gradient(#363b4a, #0b0c0f)" }}>
          <Footer />
          <Copyright />
        </section>
      </MainWrapper>
    );
  }
}

const MainWrapper = styled.div`
  padding: 0 2rem;
  background: linear-gradient(#363b4a, #0b0c0f);
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  margin: 0;
`;
